<template>
  <div class="wrapper-input-range">
    <input class="slider"
           @input="inputSlider($event.target.value)"
           @change="changeSlider($event.target.value)"
           :id="id"
           :min="min_value"
           :max="max_value"
           :value="changedValue"
           step="1"
           type="range" />
    <span class="range-fill" :style="{'width': rangeFillWidth  + '%'}"></span>
  </div>
</template>

<script>
export default {
name: "CustomInputRange",
  props: {
    min_value:{
      type: Number,
      default : 1
    },
    max_value:{
      type: Number,
      default : 100
    },
    selected_value:{
      type: Number,
      default : 50
    },
    id: {
      type: String,
      required: true,
    }
  },
  computed: {
    rangeFillWidth(){
      return (this.selected_value - this.min_value) * 100 /  (this.max_value - this.min_value);
    }
  },
  watch: {
    selected_value: {
      immediate: true,
      handler(newVal){
        this.changedValue = newVal;
      }
    },
  },
  data: () => ({
    changedValue: 0,
  }),
  created() {
    this.selected_value = this.min_value
    this.changeSlider(this.selected_value)
  },
  methods: {
    changeSlider(value){
      if (value === null){return}
      this.changedValue = value;
      this.$emit('slider_change' , { inputId: this.id, inputValue: value})
    },
    inputSlider(value){
      if (value === null){return}
      this.$emit('slider_input' , { inputId: this.id, inputValue: value})
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../scss/variables.scss";
.wrapper-input-range {
  position: relative;
  margin-top: 15px;
}
.range-fill {
  position: absolute;
  display: block;
  top: 16px;
  left: 0;
  z-index: 5;
  width: 100%;
  background-color: $font-blue;
  height: 2px;
}
@-moz-document url-prefix() {
  .range-fill {
    display: none !important;
  }
}
[type=range] {
  -webkit-appearance: none;
  margin: 12px 0;
  width: 100%;
  height: 2px;
  background-color: #E6E8FF;
}
[type=range]:focus {
  outline: 0;
}
input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  color: #13bba4;
}

input[type="range"]::-moz-range-progress {
  background-color: $font-blue;
  height: 2px;
}

input[type="range"]::-ms-fill-lower {
  background-color: $font-blue;
}

input[type="range"]::-ms-fill-upper {
  background-color: $font-blue;
}


/*Точка*/
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  box-sizing: border-box;
  height: 28px;
  width: 28px;
  border: 3px solid #FFFFFF;
  box-shadow: 0px 0px 1px 1px #1a4576;
  border-radius: 50%;
  background: url('../assets/icn_slider.svg') no-repeat center ,$font-blue;
  z-index: 99;
  position: relative
}
input[type=range]::-webkit-slider-thumb :hover{
  background: url('../assets/icn_slider.svg') no-repeat center ,$button-hover;
}
/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  cursor: pointer;
  box-sizing: border-box;
  height: 28px;
  width: 28px;
  border: 3px solid #FFFFFF;
  box-shadow: 0px 0px 1px 1px #1a4576;
  border-radius: 50%;
  background: url('../assets/icn_slider.svg') no-repeat center , $font-blue;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  cursor: pointer;
  box-sizing: border-box;
  height: 28px;
  width: 28px;
  border: 3px solid #FFFFFF;
  box-shadow: 0px 0px 1px 1px #1a4576;
  border-radius: 50%;
  background: url('../assets/icn_slider.svg') no-repeat center , $font-blue;
  z-index: 99;
}

[type=range]:focus::-webkit-slider-thumb  {
  background-color: #082E60;
}
[type=range]:focus::-moz-range-thumb  {
  background-color: #082E60;
}
[type=range]:focus::-ms-thumb  {
  background-color: #082E60;
}
[type=range]:focus + .range-fill  {
  background-color: #082E60;
}
[type=range]:hover::-webkit-slider-thumb  {
  background-color: #082E60;
}
[type=range]:hover::-moz-range-thumb  {
  background-color: #082E60;
}
[type=range]:hover::-ms-thumb  {
  background-color: #082E60;
}
[type=range]:hover + .range-fill  {
  background-color: #082E60;
}

@media screen and (max-width: 420px) {
  .wrapper-input-range {
    position: relative;
    margin-top: 5px;
  }
}

</style>