<template>
  <b-modal id="req-full-rep"
           body-bg-variant="coool"
           header-bg-variant="coool"
           header-class="header-class"
           hide-footer
           size="lg"
           :scrollable="innerWidth < 421"
           no-stacking
           dialog-class="modal-dialog-class"
           body-class="modal-class">
    <div v-if="innerWidth > 421" class="close-btn" @click="$bvModal.hide('req-full-rep')">
      <img src="../../assets/icn_close_modal.svg" class="cross" alt="X">
    </div>
    <FullReport_OLD></FullReport_OLD>
  </b-modal>
</template>

<script>
import FullReport_OLD from '@/views/results/FullReport_OLD';
export default {
name: "RequestFullReport",
  data:()=>({
    innerHeight: window.innerHeight,
  }),
  components:{
    FullReport_OLD
  },
  computed:{
    innerWidth(){
      return window.innerWidth
    }
  },
  created() {
    window.addEventListener('resize', this.updateViewportHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateViewportHeight);
  },
  methods:{
    updateViewportHeight(){
      document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);
      this.innerHeight = window.innerHeight
    }
  }
}
</script>

<style>
#req-full-rep___BV_modal_header_{
  display: none;
}
.modal-lg{
  max-width: 888px!important;
}
.modal-class{
  padding: 32px!important;
}

@media screen and (max-width: 1025px) {
  #req-full-rep___BV_modal_header_{
    display: block;
  }
}
@media screen and (max-width: 420px) {
  .modal-dialog-class{
    height: calc(100% )!important;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% )!important;
  }
  .modal-dialog-scrollable .modal-content {
    margin-top: 40px;
    position: static!important;
    max-height: calc(100%)!important;
  }
  .modal-class{
    padding: 16px!important;
    position: static!important;
  }
  .modal-lg{
    margin: 0!important;
  }
  #req-full-rep___BV_modal_header_{
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .modal-class{
    padding: 16px!important;
  }
  .modal-lg{
    margin: 0!important;
  }
  #req-full-rep___BV_modal_header_{
    display: none;
  }
}
@media screen and (max-width: 375px) {
  .modal-class{
    padding: 16px!important;
  }
  .modal-lg{
    margin: 0!important;
  }
  #req-full-rep___BV_modal_header_{
    display: none;
  }
}
</style>
<style scoped>
.header-class{
  border-radius: 10px!important;
}
.close-btn{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #4b4d56;
  box-shadow: 0 2px 4px 0 rgba(26, 41, 60, 0.15);
  position: absolute;
  top: 0;
  right: -300px;
  cursor: pointer;
  z-index: 999;
  padding: 7px 12px;
}
@media screen and (max-width: 420px) {
  .close-btn{
    display: none;
  }
}

</style>