<template>
  <div>
    <div class="wrap-top-blocks flex-container ">
      <div class="block-rect">
        <PreLoader v-if="is_loading"></PreLoader>
        <div v-else>
          <div v-if="!result" class="comparison-level-title">
            No data
          </div>
          <table v-else class="styled-table">
            <thead>
              <tr>
                <th></th>
                <th>No Programs/<br />As of Right</th>
                <th>485x Only</th>
                <th>485x + UAP</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Max Res Floor Area</td>
                <td>{{ num(opts['none']['regular'].maxResFloorArea) }}</td>
                <td>{{ num(opts['x485']['regular'].maxResFloorArea) }}</td>
                <td>{{ num(opts['uap']['regular'].maxResFloorArea) }}</td>
              </tr>
              <tr>
                <td>Max # of Res Units</td>
                <td>{{ num(opts['none']['regular'].maxNumberOfResUnits) }}</td>
                <td>{{ num(opts['x485']['regular'].maxNumberOfResUnits) }}</td>
                <td>{{ num(opts['uap']['regular'].maxNumberOfResUnits) }}</td>
              </tr>
              <tr>
                <td>Affordable Housing Floor Area</td>
                <td>{{ num(opts['none']['regular'].affHousingFloorArea) }}</td>
                <td>{{ num(opts['x485']['regular'].affHousingFloorArea) }}</td>
                <td>{{ num(opts['uap']['regular'].affHousingFloorArea) }}</td>
              </tr>
              <tr>
                <td>Projected # of Affordable Units and AMI level</td>
                <td>
                  <div v-for="(item, index) in opts['none']['regular'].projNumberOfAffHousingUnitsSet" :key="index">
                    {{ item.units }} at {{ item.ami }}% AMI
                  </div>
                </td>
                <td>
                  <div v-for="(item, index) in opts['x485']['regular'].projNumberOfAffHousingUnitsSet" :key="index">
                    {{ item.units }} at {{ item.ami }}% AMI
                  </div>
                </td>
                <td>
                  <div v-for="(item, index) in opts['uap']['regular'].projNumberOfAffHousingUnitsSet" :key="index">
                    {{ item.units }} at {{ item.ami }}% AMI
                  </div>
                </td>
              </tr>
              <tr>
                <td>Projected # of Free Market Units</td>
                <td>{{ num(opts['none']['regular'].projNumberOfFreeMarketUnits) }}</td>
                <td>{{ num(opts['x485']['regular'].projNumberOfFreeMarketUnits) }}</td>
                <td>{{ num(opts['uap']['regular'].projNumberOfFreeMarketUnits) }}</td>
              </tr>
              <tr>
                <td>Post Completion Property Tax</td>
                <td>{{ num(opts['none']['regular'].postCompletionPropTax) }}</td>
                <td>{{ num(opts['x485']['regular'].postCompletionPropTax) }}</td>
                <td>{{ num(opts['uap']['regular'].postCompletionPropTax) }}</td>
              </tr>
              <tr>
                <td>Tax Savings</td>
                <td>{{ num(opts['none']['regular'].taxSavingsPerYear) }}</td>
                <td>{{ num(opts['x485']['regular'].taxSavingsPerYear) }}</td>
                <td>{{ num(opts['uap']['regular'].taxSavingsPerYear) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="">
      <FullReport></FullReport>
    </div>
  </div>
</template>

<script>
import FullReport from "@/views/results/FullReport";
import PreLoader from "@/components/Preloader";
import { mapGetters } from "vuex";
import axios from "axios";
import sample, { buildOptionsMap } from "@/store/sample";
import { number } from '@/utils'

// const https = require('https');

export default {
  name: "Results",
  components: {
    FullReport,
    PreLoader
  },
  data: () => ({
    asses_level: 'Calculating..',
    is_loading: true,
    result: null
  }),
  computed: {
    ...mapGetters({
      bblId: 'get_bblId',
      area_custom: 'get_area_custom',
    }),
    opts() {
      console.log(sample, buildOptionsMap)
      if(this.result) {
        return buildOptionsMap(this.result.options)
      }
      return {}
    }
  },
  watch: {
    // bblId: {
    //   immediate: true,
    //   handler: function () {
    //   },
    // }
  },
  created() {
    console.log('created resultz.vue', Date.now())
    this.getData()
  },
  methods: {
    num(value) {
      return number(value)
    },
    getData() {
      if (!this.bblId) {
        return false
      }
      console.log('resultz.vue getData', Date.now())
      let params = {
        'bbleId': this.bblId,
        "landAreaTotal": this.area_custom,
        'avgTaxPerSf': null,
        'avBeforeConstruction': null,
      }

      let url = `https://24.144.103.158:5134/evaluate`

      this.is_loading = true;
      console.log('resultz.vue then resp', params, Date.now())
      // const agent = new https.Agent({
      //   rejectUnauthorized: false
      // });
      // console.log(agent)

      // axios({
      //   url,
      //   method: "POST",
      //   params,
      //   httpsAgent: new https.Agent({
      //     rejectUnauthorized: false
      //   })
      // })

      axios.post(url, params)
        .then(resp => {
          console.log('resultz.vue then resp', resp)
          this.result = resp.data
        }).catch(err => {
          console.error('resultz.vue catch err', err)
        }).finally(() => {
          this.is_loading = false;
        })
    },


  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.rectangle-square {
  height: 91px;
  width: 91px;
  border-radius: 10px;
  padding: 10px;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
}

.white-rect {
  border: 1px solid #E6E8FF;
}

.line-arrow {
  line-height: 16px;
  margin-bottom: auto;
  fill: $font-black;
}

.recomm-emoji {
  width: 38px;
}

.icon-recommend {
  margin: 19px;
}

.wrap-comparison-level-percent {
  height: 115px;
  width: 376px;
  border-radius: 8px;
  background-color: rgba(171, 200, 239, .1);
  padding: 12px;
  margin-top: 12px;
}

.speedometer-wrapper {
  border-bottom: 1px solid rgba(150, 168, 190, .2);
  margin-bottom: 11px;
  padding-bottom: 11px;
  text-align: center;
  position: relative;
}

.speedometer-arrow {
  position: absolute;
  bottom: 2px;
  left: 105px;
  -webkit-transform-origin: 85% 85%;
  -moz-transform-origin: 85% 85%;
  -o-transform-origin: 85% 85%;
  -ms-transform-origin: 85% 85%;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;

}

.speedometer-arrow-0 {
  transform: rotate(-46deg);
}

.speedometer-arrow-1 {
  transform: rotate(-35deg);
}

.speedometer-arrow-2 {
  transform: rotate(-23deg);
}

.speedometer-arrow-3 {
  transform: rotate(2deg);
}

.speedometer-arrow-4 {
  transform: rotate(14deg);
}

.speedometer-arrow-5 {
  transform: rotate(39deg);
}

.speedometer-arrow-6 {
  transform: rotate(52deg);
}

.speedometer-arrow-7 {
  transform: rotate(77deg);
}

.speedometer-arrow-8 {
  transform: rotate(89deg);
}

.speedometer-arrow-9 {
  transform: rotate(113deg);
}

.speedometer-arrow-10 {
  transform: rotate(125deg);
}

.wrap-top-blocks {
  margin-top: 0;
}

.fb-logo {
  background: url('../assets/facebook_logo.svg') no-repeat center;
}

.gm-logo {
  background: url('../assets/google_logo.svg') no-repeat center;
}

.fb-logo,
.gm-logo {
  background-position-x: 9px;
  background-position-y: 5px;
}

.arrow-up {
  height: 12px;
  width: 20px;
  margin-left: 8px;
}

.block-rect {
  width: 100%;
  min-height: 300px;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(26, 41, 60, 0.1);
  margin-bottom: 24px;
  padding: 24px;
}

.basic-and-recomm {
  height: 214px;
}

.assess-level {
  height: 370px;
  width: 424px;
}

.assess-level-title {
  color: #8ECC31;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.35px;
  line-height: 27px;
  margin-bottom: 2px;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  text-align: center;
}

.assess-level-subtitle {
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 19px;
  text-align: center;
}

.assess-level-text {
  color: $font-black;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 22px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.assess-level-text p {
  margin-bottom: 6px;
  padding-left: 20px;
  position: relative;
}

.assess-level-text p:before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #082E60;
}

.assess-level-text p.severely-underassessed:before {
  background: #80B926;
}

.assess-level-text p.moderately-underassessed:before {
  background: #A7DC59;
}

.assess-level-text p.fairly-assessed:before {
  background: #FFE086;
}

.assess-level-text p.moderately-overassessed:before {
  background: #FFB57B;
}

.assess-level-text p.severely-overassessed:before {
  background: #FF7564;
}

.comparison {
  height: 370px;
  width: 424px;
  margin-left: 24px;
}

.comparison-level-title {
  color: $font-black;
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 27px;
  margin-bottom: 2px;
  text-align: center;
}

.comparison-level-subtitle {
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 24px;
  text-align: center;
}

.comparison-level-percent {
  color: $font-black;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.56px;
  font-family: "Open Sans";
  line-height: 27px;
  position: relative;
  margin-bottom: 0;
}

.arrow-img {
  content: '';
  position: absolute;
  top: 0;
  right: -30px;
  display: block;
  width: 20px;
  height: 12px;
}

.comparison-level-percent-description {
  height: 44px;
  width: 185px;
  color: $font-black;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 24px auto;
}

.comparison-details {
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  background-color: rgba(171, 200, 239, .1);

}

.comparison-details-perceived {
  height: 44px;
  border-radius: 8px;
  color: #0A1250;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 11px 16px;
  margin-bottom: 8px;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  min-width: 72px;
}

.comparison-details-perceived.perc-brdr {
  border: 1px solid #CDD1FF;
}

.comparison-details-perceived.perc-fll {
  background-color: #CDD1FF;
}

.comparison-details-perceived span {
  font-weight: 600;
  color: $font-black;
  margin-left: 5px;
}


.comparison-details-actual {
  height: 44px;
  border-radius: 8px;
  min-width: 72px;
  color: #0A1250;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 11px 16px;
  margin-bottom: 0;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
}

.comparison-details-actual.act-brdr {
  border: 1px solid #FFB57B;
}

.comparison-details-actual.act-fll {
  background-color: #FFB57B;
}

.comparison-details-actual span {
  font-weight: 600;
  color: $font-black;
  margin-left: 5px;
}

.basic-and-recomm-props-wrap {
  width: 376px;
  border-radius: 10px;
  background-color: $common-bg;
  padding: 12px;
}

.p-descr {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.basic-and-recomm-title {
  text-align: center;
  color: $font-black;
  font-family: "Open Sans";
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 27px;
  margin-bottom: 24px;
}

.basic-and-recomm-props {
  height: 22px;
  width: 240px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: right;
  white-space: nowrap;
  /* Запрещаем перенос строк */
  overflow: hidden;
  /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis;
  /* Добавляем многоточие */
}

.basic-and-recomm-props-descriprion {
  height: 22px;
  width: 99px;
  color: #546C92;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 3px;
}

.recommendation-block {
  height: 115px;
  width: 376px;
  border-radius: 10px;
  padding: 12px;
}

.recommendation-block.success {
  background-color: rgba(167, 220, 89, 0.15);
}

.recommendation-block.reject {
  background-color: #FFF0E5;
}

.recommendation-block-text {
  height: 27px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 27px;
  margin-top: 32px;
  margin-right: 68px;
  /*background: url('../assets/icn_arrow_rounded_Color.svg') no-repeat center ;*/
}

.recommendation-block-icon {
  height: 50px;
  width: 50px;
  border: 3px solid #FFFFFF;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(26, 41, 60, 0.15);
}

.sign-up-title {
  color: $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin-bottom: 2px;
}

.sign-up-subtitle {
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 20px;
}

.sign-up-form {
  width: 544px;
  margin: 0 auto;
}

.socialite-btn {
  color: $font-black;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  height: 48px;
  width: 264px;
  border-radius: 6px;
  background-color: $common-bg;
  border: 1px solid $common-bg;
  margin-right: 16px;
}

.socialite-btn:last-of-type {
  margin-right: 0;
}

.or-block {
  display: block;
  height: 60px;
  width: 100%;
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
  position: relative;
  margin: 0 auto;
}

.or-block:before,
.or-block:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 5;
  height: 1px;
  width: 188px;
  background-color: #D2D9E2;
  top: 24px;
}

.or-block:before {
  left: 0;
}

.or-block:after {
  right: 0;
}

.sign-up-first-name,
.sign-up-last-name,
.sign-up-email {
  height: 48px;
  width: 264px;
  border-radius: 6px;
  background-color: $common-bg;
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  padding: 0 16px;
  border: 1px solid $common-bg;
  outline: none;
}

.sign-up-first-name::placeholder,
.sign-up-last-name::placeholder,
.sign-up-email::placeholder {
  color: $font-gray;
}

.sign-up-first-name:focus,
.sign-up-last-name:focus,
.sign-up-email:focus {
  outline: none;
  background-color: #FFFFFF;
  border: 1px solid #D2D9E2;
}

.sign-up-first-name {
  margin-right: 16px;
}

.sign-up-email {
  width: 100%;
  margin-top: 16px;
}

.btn-default {
  width: 264px;
  display: block;
  margin: 24px auto 0;
}

.justify-content-between {
  justify-content: space-between !important;
}

.margin-left-38 {
  margin-left: 38px;
}

.flex-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.sign-up {
  height: 412px;
}

.full-rep {
  height: 168px;
}

.small-block {
  display: none;
}

@media screen and (max-width: 768px) {
  .speedometer-arrow {
    width: 88px;
    left: 86px;
  }

  .wrap-top-blocks {
    margin-top: -24px;
    padding: 24px;
  }

  .rec-wrap {
    margin-top: 24px;
  }

  .block-rect {
    height: 421px;
    width: 348px;
    padding: 20px;
    margin-bottom: 0;
  }

  .assess-level-text {
    font-size: 12px;
    line-height: 22px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .margin-left-38 {
    margin-left: 0
  }

  .wrap-comparison-level-percent {
    height: 115px;
    width: 308px;
    border-radius: 10px;
    padding: 12px;
    margin-top: 12px;
  }

  .wrap-low-blocks {
    margin-top: -24px;
    padding: 24px;
    flex-direction: column;
  }

  .full-rep {
    width: 720px;
    height: 164px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .small-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 70px;
    width: 720px;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(26, 41, 60, 0.1);
    padding: 13px 200px;
    margin-bottom: 24px;
  }

  .btn-clss {
    width: 320px;
    display: block;
    margin: 0;
  }

  .basic-and-recomm {
    height: 202px;
    width: 100%;
  }

  .basic-and-recomm-props-descriprion {
    width: 118px;
  }

  .basic-and-recomm-props {
    width: 208px;
  }

  .basic-and-recomm-props-wrap {
    width: 100%;
  }

  .recommendation-block {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .basic-and-recomm-title {
    margin-bottom: 20px;
  }

  .rec-wrap {
    margin-top: 0;
  }

  .speedometer-arrow {
    width: 95px;
    left: 96px;
  }

  .wrap-top-blocks {
    margin-top: -24px;
    padding: 0px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .comparison {
    height: 353px;
    width: 390px;
    margin-left: 0;
    margin-top: 6px;
  }

  .block-rect {
    border-radius: 0px;
    padding: 16px;
    width: 390px;
    margin-right: auto;
    margin-left: auto;
  }

  .btn-clss {
    width: 358px;
  }

  .assess-level {
    margin-top: 24px;
    height: 373px;
  }

  .small-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 70px;
    width: 720px;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(26, 41, 60, 0.1);
    padding: 13px 200px;
    margin-bottom: 24px;
  }

  .btn-clss {
    width: 320px;
    display: block;
    margin: 0;
  }

  .basic-and-recomm {
    height: 202px;
    width: 100%;
  }

  .basic-and-recomm-props-descriprion {
    width: 118px;
  }

  .basic-and-recomm-props {
    width: 208px;
  }

  .basic-and-recomm-props-wrap {
    width: 100%;
  }

  .recommendation-block {
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .speedometer-arrow {
    width: 90px;
    left: 89px;
  }

  .block-rect {
    border-radius: 0;
    padding: 16px;
    width: 375px;
    height: 351px;
  }

  .assess-level-title {
    font-size: 16px;
    line-height: 24px;
  }

  .assess-level-subtitle {
    font-size: 11px;
    line-height: 15px;
  }

  .assess-level-text {
    font-size: 11px;
    line-height: 22px;
  }

  .comparison-level-title {
    font-size: 16px;
    line-height: 24px;
  }

  .comparison-level-subtitle {
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 16px;
  }

  .wrap-comparison-level-percent {
    width: 343px;
    border-radius: 10px;
    height: 100px;
  }

  .small-block {
    width: 375px;
  }

  .btn-clss {
    width: 343px;
  }

  .basic-and-recomm-title {
    font-size: 16px;
    line-height: 24px;
  }

  .basic-and-recomm {
    height: 186px;
  }

  .recommendation-block-text {
    font-size: 16px;
    line-height: 27px;
  }

  .rectangle-square {
    height: 76px;
    width: 76px;
    border-radius: 10px;
  }

  .icon-recommend {
    margin: 9px;
  }

  .recommendation-block {
    height: 100px;
    width: 343px;
  }

  .full-rep {
    height: 203px;
  }

  .basic-and-recomm-props-wrap {
    width: 343px;
  }

  .comparison {
    height: 322px;
  }

  .comparison-level-percent {
    font-size: 28px;
    line-height: 27px
  }
}

@media screen and (max-width: 320px) {
  .block-rect {
    width: 320px;
    height: 351px;
  }

  .schkala {
    width: 288px;
  }

  .speedometer-arrow {
    width: 80px;
    left: 75px;
  }

  .assess-level-text {
    flex-direction: column;
  }

  .margin-left-38 {
    margin-left: 0;
  }

  .assess-level {
    height: 400px;
  }

  .wrap-comparison-level-percent {
    width: 288px;
    border-radius: 10px;
    height: 104px;
  }

  .small-block {
    width: 320px;
  }

  .btn-clss {
    width: 288px;
  }

  .basic-and-recomm-props-wrap {
    width: 288px
  }

  .basic-and-recomm-props-descriprion {
    width: 125px;
  }

  .basic-and-recomm {
    height: 186px;
  }

  .basic-and-recomm-title {
    margin-bottom: 16px;
  }

  .recommendation-block {
    width: 288px;
  }

  .full-rep {
    height: 203px;
  }
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: 'Open Sans', sans-serif;
  color: $font-black;
  min-width: 400px;
  box-shadow: 0 2px 8px 0 rgba(26, 41, 60, 0.1);
}

.styled-table thead tr {
  background-color: $light-blue;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid $light-blue;
}
</style>