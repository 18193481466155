import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import Cookies from 'js-cookie'
import axios from "axios";
Vue.use(Vuex)

const getDefaultState = () => {
  return {
    area: null, // Gross Floor Area (sf)
    latitude: null,
    longitude: null,
    address: null,
    block: null,
    boroughCode: null,
    lot: null,
    currentFiscalYear: null,
    bblId: null,
    zoning: ' ',
    neighborhoodName: ' ',
    buildingClassDescription: ' ',
    //
    area_custom: null,
    build_date: null,
  }
}
export default new Vuex.Store({


  state: getDefaultState(),
  getters: {
    get_area: state => state.area ,
    get_latitude: state => state.latitude,
    get_longitude: state => state.longitude,

    get_address: state => state.address,
    get_block: state => state.block,
    get_boroughCode: state => state.boroughCode,
    get_lot: state => state.lot,
    get_currentFiscalYear: state => state.currentFiscalYear,
    get_bblId: state => state.bblId,
    get_zoning: state => state.zoning,
    get_neighborhoodName: state => state.neighborhoodName,
    get_buildingClassDescription: state => state.buildingClassDescription,

    get_all: state => state,

    get_area_custom: state => state.area_custom,
    get_build_date: state => state.build_date,
  },
  mutations: {
    changeInputRangeParams(state, {name, value}) {
      Vue.set(state, name , null );
      Vue.set(state, name , value );
      Cookies.set(`${router.currentRoute.params.id}${name}` , value, { sameSite: 'strict' , expires: new Date(new Date().getTime() + 300000 ) })
    },
    setGoogleCords(state, {latitude, longitude}) {
      state['latitude'] = latitude;
      state['longitude'] = longitude;
    },
    setAddress(state, { address, block, boroughCode, lot,currentFiscalYear, bblId, neighborhoodName, buildingClassDescription }) {
      state['currentFiscalYear'] = currentFiscalYear;
      state['address'] = address;
      state['block'] = block;
      state['boroughCode'] = boroughCode;
      state['lot'] = lot;
      state['bblId'] = bblId;
      state['neighborhoodName'] = neighborhoodName && neighborhoodName.length ?  neighborhoodName.charAt(0).toUpperCase() + neighborhoodName.slice(1).toLowerCase() : 'N/A';
      state['buildingClassDescription'] = buildingClassDescription && buildingClassDescription.length ? buildingClassDescription.charAt(0).toUpperCase() + buildingClassDescription.slice(1).toLowerCase() : 'N/A';

    },
    setPrices(state, {income, expenses, area, zoning }) {
      state['income'] = income || 0;
      state['expenses'] = expenses || 0;
      state['area'] = area || 0;
      state['area_custom'] = area || 0;
      state['zoning'] = zoning && zoning.length ?  zoning.charAt(0).toUpperCase() + zoning.slice(1).toLowerCase() : 'N/A';
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    resetCookies (state, last_id) {
      Cookies.remove(`${last_id}area`)
      Cookies.remove(`${last_id}zoning`)
    },
    setAreaCustom(state, value) {
      console.log("setAreaCustom", value)
      state['area_custom'] = value || state.area
    },
    setBuildDate(state, value) {
      console.log("setBuildDate", value)
      state['build_date'] = value || null
    },    
  },
  actions: {
    addToLocalStorage(
      // { getters, commit }
    ) {
      let res_data = []
      if (localStorage.getItem('resultsSent')) {
        try {
          res_data = JSON.parse(localStorage.getItem('resultsSent'));
        } catch(e) {
          localStorage.removeItem('resultsSent');
        }
      }
      // res_data.push(`${getters.get_income_client}-${getters.get_expenses_client}-${getters.get_area_client}-${getters.get_vacant_area}-${getters.get_rented_area_sf}-${getters.get_owner_area_sf}`)

      const parsed = JSON.stringify(res_data);
      localStorage.setItem('resultsSent', parsed);
      // commit('resSent', parsed)
    },

   async SendInfo({getters, commit}){
     if(!getters.get_guid){
       commit('setGuid')
     }
     let data_send = {
       'tx_guid' : getters.get_guid,
       'tx_bbleId' : getters.get_bblId,
       'tx_year' : getters.get_currentFiscalYear,
       'tx_income' : getters.get_income_client,
       'tx_expenses' : getters.get_expenses_client,
       'tx_area' : getters.get_area_client,
       'tx_relationshipToProperty' : getters.get_relationshipToProperty,
       'tx_vacanciesInTheLastTwelveMonths' : getters.get_vacanciesInTheLastTwentyMonths,
       'tx_vacantArea' : getters.get_vacant_area,
       'tx_ownerOccupancyInTheLastTwelveMonths' : getters.get_ownerOccupancyInTheLastTwentyMonths,
       'tx_rentedArea_sf' : getters.get_rented_area_sf,
       'tx_ownerAreaSf' : getters.get_owner_area_sf,
       'tx_address' : getters.get_address,
       'tx_block' : getters.get_block,
       'tx_boroughCode' : getters.get_boroughCode,
       'tx_lot' : getters.get_lot,
       'tx_scoreResults' : getters.scoreResults,
       'tx_action' : getters.tx_action,
       'tx_reason' : getters.tx_reason,

     }
      return await axios.post('https://mgny.force.com/sales/services/apexrest/lead/add', data_send )
      // return await axios.post('https://devmgny02-mgny.cs14.force.com/Billing/services/apexrest/lead/add', data_send )
    }
  },
  modules: {
  }
})
