<template>
  <div class="calendar">
    <div class="calendar-header">
      <button @click="prevMonth">
        <svg width="16px" height="10px" viewBox="0 0 16 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>icn_back</title>
          <g id="new-design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="1" transform="translate(-523.000000, -31.000000)" fill="#96A8BE">
              <g id="progress_bar" transform="translate(508.000000, 16.000000)">
                <path
                  d="M29.9950135,19.0001492 L18.4050135,19.0001492 L20.7050135,16.712417 C20.9586721,16.4590097 21.0577371,16.0896604 20.9648916,15.7434995 C20.8720461,15.3973385 20.6013956,15.1269561 20.2548916,15.0342026 C19.9083875,14.941449 19.5386721,15.0404159 19.2850135,15.2938233 L15.2850135,19.289862 C15.1939729,19.3848716 15.1226079,19.4969055 15.0750135,19.6195352 C14.9749955,19.8627555 14.9749955,20.1355624 15.0750135,20.3787826 C15.1226079,20.5014124 15.1939729,20.6134463 15.2850135,20.7084558 L19.2850135,24.7044946 C19.4727801,24.8936198 19.728376,25 19.9950135,25 C20.261651,25 20.5172469,24.8936198 20.7050135,24.7044946 C20.8943262,24.516914 21.0008118,24.2615712 21.0008118,23.9951977 C21.0008118,23.7288242 20.8943262,23.4734815 20.7050135,23.2859008 L18.4050135,20.9981686 L29.9950135,20.9981686 C30.5472983,20.9981686 30.9950135,20.5508968 30.9950135,19.9991589 C30.9950135,19.4474211 30.5472983,19.0001492 29.9950135,19.0001492 Z"
                  id="icn_back"></path>
              </g>
            </g>
          </g>
        </svg>
      </button>
      <span>{{ monthNames[currentMonth] }} {{ currentYear }}</span>
      <button @click="nextMonth">
        <svg width="16px" height="10px" viewBox="0 0 16 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>icn_forward</title>
          <g id="new-design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="2" transform="translate(-820.000000, -654.000000)" fill="#FFFFFF">
              <g id="block" transform="translate(284.000000, 225.000000)">
                <g id="Group" transform="translate(24.000000, 412.000000)">
                  <g id="button" transform="translate(280.000000, 0.000000)">
                    <path
                      d="M246.995014,21.0001492 L235.405014,21.0001492 L237.705014,18.712417 C237.958672,18.4590097 238.057737,18.0896604 237.964892,17.7434995 C237.872046,17.3973385 237.601396,17.1269561 237.254892,17.0342026 C236.908388,16.941449 236.538672,17.0404159 236.285014,17.2938233 L232.285014,21.289862 C232.193973,21.3848716 232.122608,21.4969055 232.075014,21.6195352 C231.974995,21.8627555 231.974995,22.1355624 232.075014,22.3787826 C232.122608,22.5014124 232.193973,22.6134463 232.285014,22.7084558 L236.285014,26.7044946 C236.47278,26.8936198 236.728376,27 236.995014,27 C237.261651,27 237.517247,26.8936198 237.705014,26.7044946 C237.894326,26.516914 238.000812,26.2615712 238.000812,25.9951977 C238.000812,25.7288242 237.894326,25.4734815 237.705014,25.2859008 L235.405014,22.9981686 L246.995014,22.9981686 C247.547298,22.9981686 247.995014,22.5508968 247.995014,21.9991589 C247.995014,21.4474211 247.547298,21.0001492 246.995014,21.0001492 Z"
                      id="icn_forward"
                      transform="translate(239.997507, 22.000000) rotate(-180.000000) translate(-239.997507, -22.000000) ">
                    </path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
    <div class="calendar-body">
      <div class="calendar-weekdays">
        <span v-for="day in weekDays" :key="day">{{ day }}</span>
      </div>
      <div class="calendar-days">
        <span v-for="day in blankDays" :key="'blank-' + day" class="calendar-day--blank"></span>
        <span v-for="day in daysInMonth" :key="day" :class="{ 'calendar-day--selected': isSelected(day) }"
          @click="selectDate(day)">
          {{ day }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      selectedDate: null,
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      weekDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    };
  },
  computed: {
    daysInMonth() {
      const date = new Date(this.currentYear, this.currentMonth + 1, 0);
      return Array.from({ length: date.getDate() }, (_, i) => i + 1);
    },
    blankDays() {
      const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1).getDay();
      return Array.from({ length: firstDayOfMonth }, (_, i) => i);
    }
  },
  methods: {
    prevMonth() {
      if (this.currentMonth === 0) {
        this.currentMonth = 11;
        this.currentYear--;
      } else {
        this.currentMonth--;
      }
    },
    nextMonth() {
      if (this.currentMonth === 11) {
        this.currentMonth = 0;
        this.currentYear++;
      } else {
        this.currentMonth++;
      }
    },
    selectDate(day) {
      this.selectedDate = new Date(this.currentYear, this.currentMonth, day);
      this.$emit('date-selected', this.selectedDate);
    },
    isSelected(day) {
      return this.selectedDate && this.selectedDate.getDate() === day && this.selectedDate.getMonth() === this.currentMonth && this.selectedDate.getFullYear() === this.currentYear;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.calendar {
  color: $font-black;

  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  border: 1px solid $common-bg;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(26, 41, 60, 0.1);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: $light-blue;
  border-bottom: 1px solid #ccc;
}

.calendar-header button {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;

  svg * {
    fill: $font-black;
  }
}

.calendar-body {
  padding: 10px;
}

.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 600;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-size: 12px;
}

.calendar-days span {
  padding: 8px;
  cursor: pointer;
  text-align: center;
}

.calendar-day--blank {
  padding: 8px;
}

.calendar-day--selected {
  background-color: $font-blue;
  color: #fff;
  border-radius: 5px;
}
</style>
