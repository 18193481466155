<template>
  <b-modal id="not-expect"
           body-bg-variant="coool"
           header-bg-variant="coool"
           header-class="header-class"
           @hidden="resetModal()"
           hide-footer
           size="lg"
           class="bottom-modal"
           body-class="modal-class"
           dialog-class="modal-dialog-bottom">
    <div class="close-btn" @click="$bvModal.hide('not-expect')">
      <img src="../../assets/icn_close_modal.svg" class="cross" alt="X">
    </div>
    <Preloader v-if="is_loading"></Preloader>
    <div v-if="sent_success" class="sent-pad text-center">
      <div class="title-main text-center">
        <div>Thank you for your feedback!</div>
      </div>
      <p class="title-descr text-center">
        Your responses help us build more valuable products for the real estate industry.
      </p>
      <button @click="$bvModal.hide('not-expect')"  class="help-btn" >Done!</button>
    </div>
    <div v-else-if="sent_error" class="sent-pad text-center">
        <p class="title-main">Something went wrong</p>
        <div class="descr form-sent">
          <p class="mb-0">We are working on this problem now. </p>
          <p class="mb-0">Please try again or contact us: </p>
          <p class="mb-0"><b>718-522-1111</b></p>
        </div>
      <button @click="$bvModal.hide('not-expect')"  class="help-btn" >Hide</button>
    </div>
    <div v-else>
      <div class="title-main text-center">
          <div>This preliminary report didn’t meet
            your expectations. Why?</div>
      </div>
      <p class="title-descr text-center" :class="validation_error ? 'err' : ''">
        We want to make our service better for you. Please select an answer below.
      </p>
      <div class="form-wrap">
        <div class="wrapper-checkbox">
          <input type="checkbox" id="expensive" name="properties" v-model="known_info" :true-value="true"  :false-value="false"  >
          <label for="expensive"><span class="text-label">I already knew this information</span></label>
          <span class="radio-checked-detector"  @click="known_info = !known_info" ></span>
        </div>
        <div class="wrapper-checkbox">
          <input type="checkbox" id="notClear" name="properties" v-model="new_info" :true-value="true" :false-value="false"  >
          <label for="notClear"><span class="text-label">I learned something new, but don’t see how this information can help me</span></label>
          <span class="radio-checked-detector" @click="new_info = !new_info"></span>
        </div>
        <div class="wrapper-checkbox">
          <input type="checkbox" id="enoughInformation" name="properties" v-model="enough_info" :true-value="true"  :false-value="false"  >
          <label for="enoughInformation"><span class="text-label">Already received enough information to make a decision on the next steps</span> </label>
          <span class="radio-checked-detector"  @click="enough_info = !enough_info" ></span>
        </div>
      </div>
      <div class="d-flex justify-content-center buttons">
        <ButtonDefault :need_forward="false" btn-class="btn-clss" @click="SendRes()" text="Done"></ButtonDefault>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader.vue'
import ButtonDefault from '@/components/ButtonDefault.vue';
// import axios from "axios";
export default {
name: "NotExpectModal",
  data: () => ({
    known_info: false,
    new_info: false,
    enough_info: false,
    validation_error: false,
    sent_success:false,
    sent_error:false,
    is_loading: false
  }),
  components:{
    ButtonDefault,Preloader
  },
  methods:{
    resetModal(){
      this.known_info = false;
      this.new_info = false;
      this.enough_info = false;
      this.validation_error = false;
      this.sent_success = false;
      this.sent_error = null;
      this.is_loading =  false;
    },
    track(checkedProperty){
      this.$gtag.event('Modal selected options', {
        'event_category': "Report didn’t met expectations",
        'value': checkedProperty.join(', ')
      })
    },
   async SendRes(){

     this.validation_error = false
     let checkedProperty = [];
     if(this.known_info){checkedProperty.push('known_info')}
     if(this.new_info){checkedProperty.push('new_info')}
     if(this.enough_info){checkedProperty.push('enough_info')}
     if(checkedProperty.length === 0){
      return this.validation_error = true}
      this.track(checkedProperty)

     this.$store.commit('setReason', {
        tx_action: 'not_really',
        tx_reason : checkedProperty
      })

     await this.$store.dispatch('SendInfo').then(()=>{
       this.$store.dispatch('addToLocalStorage')
       this.sent_success = true
       this.is_loading = false;
     }).catch((error)=>{
       this.sent_error = true;
       this.is_loading = false;
       console.log('Error' + error)
     })

      // await axios.post('https://mgny.force.com/sales/services/apexrest/lead/add', {tx_action: 'not_really' ,tx_reason : checkedProperty} )
      //     // await axios.post('https://devmgny02-mgny.cs14.force.com/Billing/services/apexrest/lead/add', data_send )
      //     .then(()=>{
      //       this.$store.dispatch('addToLocalStorage')
      //       this.sent_success = true
      //       this.is_loading = false;
      //     }).catch((error)=>{
      //       this.sent_error = true;
      //       this.is_loading = false;
      //       console.log('Error' + error)
      //     })
    }
  }
}
</script>

<style>
#not-expect___BV_modal_header_{
  display: none;
}@media screen and (min-width: 1025px) {
  .modal-body {
    padding: 32px!important;
  }
}
@media screen and (max-width: 1025px) {
  #not-expect___BV_modal_header_{
    display: block;
  }
  .close-btn{
    display: block;
  }
}
@media screen and (max-width: 420px) {
  #not-expect___BV_modal_header_{
    display: none;
  }
  .close-btn{
    display: none;
  }
  .modal-content {
    border-radius: 0!important;
    border: none!important;
  }
  .modal-dialog-bottom {
    display: flex;
    align-items: flex-end;
    min-height: calc(100% );
  }
}

</style>
<style lang="scss" scoped>
@import "../../scss/variables.scss";
.close-btn{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #4b4d56;
  box-shadow: 0 2px 4px 0 rgba(26, 41, 60, 0.15);
  position: absolute;
  top: 0;
  right: -300px;
  cursor: pointer;
  z-index: 999;
  padding: 7px 13px;
}
.help-btn{
  width: 240px;
}
.err{
  color: darkred!important;
  text-decoration: underline;
}
.btn-clss{
  Width:240px
}
.form-wrap{
  padding: 0 128px;
}
.descr{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-bottom: 48px;
}
.title-main{
  width: 509px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 32px;
  letter-spacing: -0.56px;
  line-height: 43px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
}
.title-descr{
  height: 21px;
  width: 535px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 36px;
}
.wrapper-checkbox {
  height: 48px;
  width: 100%;
  border-radius: 6px;
  position: relative;
  margin-bottom: 16px;
}
.wrapper-checkbox:hover label {
  background-color: transparent;
  border: 1px solid $white-color;
}
.wrapper-checkbox:last-of-type {
  margin-right: 0;
}
.wrapper-checkbox input {
  display: none;
}
.wrapper-checkbox label {
  display: block;
  height: 48px;
  padding: 12px 16px;
  color: $font-black;
  font-family: "Open Sans";
  border-radius: 6px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  background-color: $common-bg;
  border: 1px solid $common-bg;
}
.radio-checked-detector {
  display: block;
  height: 24px;
  width: 24px;
  border: 1px solid #D2D9E2;
  border-radius: 3px;
  position: absolute;
  right: 16px;
  top: 12px;
}
input[type=checkbox]:checked + label + .radio-checked-detector{
  background: url('../../assets/radio_on_Color2.svg') no-repeat center, #3F6EC0 ;
  border-color: #3F6EC0;
}
input[type=checkbox]:checked + label {
  background-color: #104AB1;
  color: #ffffff;
}
.wrapper-checkbox input {
  display: none;
}
 input[type="checkbox"], input[type="checkbox"] {
   box-sizing: border-box;
   padding: 0;
 }
@media screen and (max-width: 1025px) {
  .form-wrap{
    padding:0;
  }
  .title-main{
    margin-top: -48px;
  }
}

@media screen and (max-width: 420px) {
  .form-wrap{
    padding:0;
  }
  .descr{
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 22px;
  }
  .title-descr{
    width: 74%;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    margin-left: auto;
    margin-right: auto;
  }
  .title-main{
    margin-bottom: 6px;
    width: 100%;
    font-size: 19px;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 16px;
  }
  .wrapper-checkbox{
    height: 64px;
  }
  .wrapper-checkbox label{
    height: 64px;
    display: flex;
    align-items: center;
  }
  .text-label{
    max-width: 289px;
  }
  .radio-checked-detector {
    right: 16px;
    top: 20px;
  }
}
@media screen and (max-width: 360px) {
  .text-label{
    max-width: 215px;
  }
  .wrapper-checkbox{
    height: 75px;
    margin-bottom: 8px;
  }
  .descr{
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 33px;
  }
  .wrapper-checkbox label{
    height: 75px;
    display: flex;
    align-items: center;
  }
}
</style>