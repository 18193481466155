const homeLocale = 'en-US'

const dateSettings = () => ({
  day: 'numeric',
  month: 'long',
  year: 'numeric',
})

const currencySettings = (fraction = 0) => ({
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: fraction,
  maximumFractionDigits: fraction,
})

const numberSettings = (fraction = 0) => ({
  minimumFractionDigits: fraction,
  maximumFractionDigits: fraction,
})

const numberIntlFormat = settings => new Intl.NumberFormat(homeLocale, settings)
const dateIntlFormat = settings => new Intl.DateTimeFormat(homeLocale, settings)

const currencyFormat = (fraction = 0) =>
  numberIntlFormat(currencySettings(fraction))
const numberFormat = (fraction = 0) =>
  numberIntlFormat(numberSettings(fraction))
const dateFormat = () => dateIntlFormat(dateSettings())

const currency = value => currencyFormat(0).format(value)
const currencyF = (value, f = 2) => currencyFormat(f).format(value)
const number = value => numberFormat(0).format(value)
const numberF = (value, f = 2) => numberFormat(f).format(value)

const withPostfix =
  postfix =>
    fn =>
      (...arg) =>
        `${fn(...arg)}${postfix}`

const percent = withPostfix('%')(number)
const percentF = withPostfix('%')(numberF)

const currencyP = (value, postfix) =>
  withPostfix(postfix)(v => currency(v))(value)

const currencyPSF = withPostfix('/sf')(currencyF)
const numberSF = withPostfix(' sf')(number)

const date = value => dateFormat().format(new Date(value))

export {
  currency,
  currencyF,
  number,
  numberF,
  date,
  percent,
  percentF,
  currencyPSF,
  numberSF,
  currencyP,
}
