<template>
  <div >
    <!-- <div v-if="resSent.includes(`${income_client}-${expenses_client}-${area_client}-${vacant_area}-${rented_area_sf}-${owner_area_sf}`) || computeresSent"> </div> -->
    <div v-if="false"></div>
    <div v-else class="block-rect full-rep">
      <h3 class="title-rep">Did you find this information useful?</h3>
      <div class="d-flex justify-content-center buttons">
        <ButtonDefault :need_forward="false" btn-class="btn-clss" v-b-modal.req-full-rep text="Yes!"></ButtonDefault>
        <ButtonDefault :need_forward="false" btn-class="btn-clss" v-b-modal.not-expect text="Not really"></ButtonDefault>
      </div>
    </div>
    <SignUpModal></SignUpModal>
    <RequestFullReport></RequestFullReport>
    <NotRequestModal></NotRequestModal>
    <NotExpectModal></NotExpectModal>
  </div>
</template>

<script>
import NotRequestModal from '@/components/NotRequestModal';
import ButtonDefault from '@/components/ButtonDefault.vue';
import SignUpModal from '@/components/SignUpModal.vue';
import RequestFullReport from '@/views/results/RequestFullReport';
import NotExpectModal from '@/views/results/NotExpectModal';
import {mapGetters} from "vuex";
export default {
  name: "FullReport",
  components:{
    ButtonDefault,
    SignUpModal,
    RequestFullReport,
    NotRequestModal,
    NotExpectModal
  },
  computed: {
    ...mapGetters({
      get_all: 'get_all',
      zoning: 'get_zoning',
      neighborhoodName: 'get_neighborhoodName',
      buildingClassDescription: 'get_buildingClassDescription',
      bblId: 'get_bblId',
      currentFiscalYear: 'get_currentFiscalYear',
      income: 'get_income',
      income_client: 'get_income_client',
      expenses: 'get_expenses',
      expenses_client: 'get_expenses_client',
      area_client: 'get_area_client',
      area: 'get_area',
      relationshipToProperty: 'get_relationshipToProperty',
      vacanciesInTheLastTwentyMonths: 'get_vacanciesInTheLastTwentyMonths',
      vacant_area: 'get_vacant_area',
      ownerOccupancyInTheLastTwentyMonths: 'get_ownerOccupancyInTheLastTwentyMonths',
      rented_area_sf: 'get_rented_area_sf',
      owner_area_sf: 'get_owner_area_sf',
      resSent: 'resSent',

    }),
    computeresSent(){
      let res = localStorage.getItem('resultsSent')
      if(!res) {return false}
      return res.includes(`${this.income_client}-${this.expenses_client}-${this.area_client}-${this.vacant_area}-${this.rented_area_sf}-${this.owner_area_sf}`)
    }

  },
  data: () => ({
    report_items1: [
        'Current Value Analysis',
        'Tax and Assessment Determination',
        'Fair Value and Tax',
        '5-year Assessment and Tax history',
        'Property Tax Heatmap',
        'Comparable Properties',
    ],
    report_items2: [
      'Taxert™ Level of Assessment',
      'Projections',
      'Reduction Estimates',
    ],
    report_items3:[
      'Tax Appeal Guidance',
      'Compliance and Tax Savings Tips',
      'Short-term Recommendations',
      'Long-term Strategy',
    ]
  }),

}
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
.full-rep-wrap{
}
.cards-wrap{
  display: flex;
  justify-content: space-between;
}
.full-rep {
  height: 168px;
}
.btn-clss{
  width:240px;
  margin: 0 6px ;
}
.block-rect{
  width: 100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(26,41,60,0.1);
  margin-bottom: 24px;
  padding: 24px;
}
.title-rep{
  height: 27px;
  width: 100%;
  color: $font-black;
  font-family: "Open Sans";
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 24px;
}
.description{
  height: 22px;
  width: 100%;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-bottom: 24px;
}

@media screen and (max-width: 768px) {
  .full-rep{
    width:720px;
    height: 164px;
    margin:0 24px 50px 24px;
  }
  .title-rep{
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 20px;
    letter-spacing: -0.35px;
    line-height: 27px;
    margin-bottom: 4px;
    font-weight: 400;
    margin-bottom: 24px;
  }
  .block-rect{
    padding: 20px;

  }
}
@media screen and (max-width: 420px) {
  .full-rep{
    width: 390px;
    height: 234px;
    margin-bottom: 20px;
  }
  .full-rep{
    height: 230px;
  }
  .buttons{
    flex-direction: column;
  }
  .cards-wrap{
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .description{
      margin-bottom: 24px;
      height: auto;
    }
  .body-item{
    width: auto;
    height: 24px!important;
  }
  .block-rect{
    border-radius: 0px;
    padding: 16px;
    width: 390px;
    margin-right: auto;
    margin-left: auto;
  }
  .btn-clss{
    margin: 0 0 8px 0;
    width: 100%;
  }

}
@media screen and (max-width: 375px) {
  .title-rep{
    margin-top:0;
    font-size: 16px;
    line-height: 24px;
  }
  .full-rep{
    height: 203px;
  }
  .description{
    font-size: 11px;
    line-height: 15px;
  }
  .block-rect {
    border-radius: 0;
    padding: 16px;
    width: 375px;
  }
}
@media screen and (max-width: 320px) {
  .block-rect {
    width: 320px;
    height: 361px;
  }
  .full-rep{
    height: 218px;
  }
}

</style>